
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import { getPlan } from '@/services/api';

import { useCreatePlan, useUpdatePlan, usePlan } from '@/composables/api';
import {
  PartialPlan,
  Plan,
  PlanInitPeriodUnit,
  PlanRecurringPeriodUnit,
  PlanShow,
  PlanBest
} from '@/interfaces/Plan';
import useFormErrors from '@/utils/validate';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialPlan = {
  name: '',
  title: '',
  init_period_count: null,
  init_period_unit: PlanInitPeriodUnit.DAY,
  init_original_price: null,
  init_discount_price: null,
  recurring_period_count: null,
  recurring_period_unit: PlanRecurringPeriodUnit.DAY,
  recurring_original_price: null,
  recurring_discount_price: null,
  show: PlanShow.YES,
  is_best: PlanBest.NO,
  sort: null
};

const periodUnitOptions = [
  {
    label: 'Day',
    value: 'day'
  },
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Year',
    value: 'year'
  }
];

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const planId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreatedLoading, mutate: create } = useCreatePlan();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdatePlan();
    const { formErrors, bindFormItemError } = useFormErrors();

    const rules = {
      name: [
        {
          required: true
        }
      ],
      title: [
        {
          required: true
        }
      ],
      init_period_count: [
        {
          required: true
        }
      ],
      init_period_unit: [
        {
          required: true
        }
      ],
      init_original_price: [
        {
          required: true
        }
      ],
      recurring_period_count: [
        {
          required: true
        }
      ],
      recurring_period_unit: [
        {
          required: true
        }
      ],
      recurring_original_price: [
        {
          required: true
        }
      ],
      sort: [
        {
          required: true
        }
      ],
      segpay_id: [
        {
          required: true
        }
      ]
      // status: [
      //   {
      //     required: true,
      //     trigger: 'change'
      //   }
      // ]
    };

    const { data, dataUpdatedAt } = usePlan({ planId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { planId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.go(-1);
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );

              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;
                  router.push({
                    path: '/plans'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      formValues,
      formRef,
      rules,
      PlanInitPeriodUnit,
      PlanShow,
      PlanBest,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      periodUnitOptions,
      formErrors,
      bindFormItemError
    };
  }
});
