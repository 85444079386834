export enum PlanInitPeriodUnit {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}

export enum PlanRecurringPeriodUnit {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}

export enum PlanShow {
  YES = 1,
  NO = 0
}

export enum PlanBest {
  YES = 1,
  NO = 0
}

export interface Plan {
  id: number
  name: string
  title: string
  init_period_count: number
  init_period_unit: PlanInitPeriodUnit
  init_original_price: number
  init_discount_price: number
  recurring_period_count: number
  recurring_period_unit: PlanRecurringPeriodUnit
  recurring_original_price: number
  recurring_discount_price: number
  show: PlanShow
  is_best: PlanBest
  sort: number
  segpay_id: string
  created_at: string
  updated_at: string
}

export type PartialPlan = Partial<Plan>;
